import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Mailchimp",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/mailchimp.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Mailchimp`}</h1>
    <p>{`Automatically push contacts to Mailchimp`}</p>
    <hr></hr>
    <p>{`Tired of manually syncing your Crisp users to Mailchimp? This integration is made for you! This integration automatically sends new Crisp users to Mailchimp`}</p>
    <p>{`Benefits of using Crisp and Mailchimp:`}</p>
    <ul>
      <li parentName="ul">{`Auto syncs contacts added in Crisp on Mailchimp`}</li>
      <li parentName="ul">{`Retarget customers on Mailchimp with emails`}</li>
      <li parentName="ul">{`Create incredible landing pages with no coding skills`}</li>
      <li parentName="ul">{`Send automatically postcards to your core users`}</li>
      <li parentName="ul">{`Build email templates with no coding skills`}</li>
      <li parentName="ul">{`Enjoy complete reporting feature to analyze users interactions`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      